import { render, staticRenderFns } from "./Game.vue?vue&type=template&id=793033cc&scoped=true&"
import script from "./Game.vue?vue&type=script&lang=js&"
export * from "./Game.vue?vue&type=script&lang=js&"
import style0 from "./Game.vue?vue&type=style&index=0&id=793033cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "793033cc",
  null
  
)

export default component.exports